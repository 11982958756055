import React, { useState, useEffect } from 'react';
import { CloseEdgeButton, SocialButton } from 'components/atoms/Button';
import { getCookie } from 'utils/cookie';
import { convertBreakLine } from 'utils/common';
import * as Styled from './index.styles';
import { OUTER_PAGE_LINK, SOCIAL, SOCIAL_LOGIN_METHOD, LOGIN_MESSAGE } from 'utils/constants';
import { useRouter } from 'next/router';
const AUTH_URL = process.env.NEXT_PUBLIC_AUTH_URL;

const Index = () => {
  const router = useRouter();
  let process = 0;
  if (typeof window === 'object') {
    if (window.document.referrer.indexOf('/pick/') !== -1) {
      process = 1;
    }
    if (window.document.referrer.indexOf('/paper?page') !== -1) {
      process = 2;
    }
  }
  const [provider, setProvider] = useState();

  useEffect(() => {
    const dec = getCookie({ name: '_pvd' });
    setProvider(SOCIAL[dec]);
  }, []);

  useEffect(() => {
    //다른 웹 페이지를 띄웁니다.

    const listen = (message) => {
      //메시지가 종료 플래그면 이벤트를 제거해줍니다.
      if (message?.data?.action === 'REDIRECT_LOGIN_COMPLETE') {
        router.replace(message.data.redirect);
        window.removeEventListener('message', listen);
      }
    };

    window.addEventListener('message', listen, false);

    return () => {
      window.removeEventListener('message', listen);
    };
  }, []);

  const socialLogin = ({ provider }) => {
    const referral = sessionStorage.getItem('_ref');
    const origin = window.location.origin;
    const pathname = `${window.location.pathname}/callback`; //&provider=${provider}
    const params = `?redirectUrl=${origin}${pathname}${referral ? `&referral=${referral}` : ''}`;

    // todo : rebuild
    // if (process === 2) {
    const winObj = window.open(
      `${AUTH_URL}/auth/${provider}${params.replace('/callback', '/callback/window')}`,
      'my_window'
    );
    var loop = setInterval(function () {
      if (winObj.closed) {
        clearInterval(loop);
        // personal-information

        // router.push('/philing/3/paper?page=personal-information');
        // router.back();
      }
    }, 1000);

    return;
    // }

    //window.location = `${AUTH_URL}/auth/${provider}${params}`;
  };

  const onClickAgreements = (e) => {
    const page = [
      OUTER_PAGE_LINK.LOGIN.TERMS_OF_USE,
      OUTER_PAGE_LINK.LOGIN.PRIVACY_POLICY,
      OUTER_PAGE_LINK.LOGIN.PRIVACY_SENSITIVE_POLICY,
    ][e.target.getAttribute('data-page')];

    if (page) {
      console.log(page);
      e.preventDefault();
      window.open(page, '_blank');
    }
  };

  return (
    <Styled.Container>
      <Styled.LoginHeaderWrap>
        <Styled.LoginHeader d-flex align-items-center justify-content-between>
          <div />
          <CloseEdgeButton />
        </Styled.LoginHeader>
      </Styled.LoginHeaderWrap>
      <Styled.LoginTitleWrap>
        <div className="svg-logo-odoctor" />
        <Styled.LoginTitle>
          {convertBreakLine(LOGIN_MESSAGE[process]['gLogin'], (line, idx) => (
            <React.Fragment key={idx}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </Styled.LoginTitle>
        <Styled.LoginDescription>간편하게 SNS로 로그인할 수 있어요</Styled.LoginDescription>
      </Styled.LoginTitleWrap>

      {provider && (
        <Styled.LatestHistory>
          최근에 <b>{provider}</b>(으)로 로그인 했어요!
        </Styled.LatestHistory>
      )}
      <Styled.SocialWrap>
        {SOCIAL_LOGIN_METHOD.map((m, i) => {
          const { text, provider } = m;
          return (
            <SocialButton
              key={i}
              social={m.provider}
              onClick={() => {
                socialLogin({ provider });
              }}
            >
              {text}
            </SocialButton>
          );
        })}
      </Styled.SocialWrap>
      <Styled.Agreement onClick={onClickAgreements}>
        로그인 시, 언에이징의 <span data-page={0}>이용약관</span>과
        <span data-page={1}>개인정보처리방침</span>,<br />
        <span data-page={2}>개인민감정보처리방침</span>에 동의하는 것으로 간주합니다.
      </Styled.Agreement>
    </Styled.Container>
  );
};

export default Index;
