import styled from 'styled-components';
import Div from 'components/atoms/div';

export const Container = styled.div`
  position: relative;
  padding-top: 11.8rem;
  font-size: 2rem;
`;

export const LoginHeader = styled(Div)`
  height: 5.8rem;
  .svg-button-close-cross {
    padding: 1rem;
    margin: -1rem;
    background-position: center;
  }
`;

export const LoginInputWrap = styled(Div)`
  margin-top: 6rem;
`;

export const LoginTitleWrap = styled.div`
  margin-bottom: 8rem;
  .svg-logo-odoctor {
    margin-bottom: 2rem;
  }
`;

export const LoginTitle = styled.h1`
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.3;
`;

export const LoginDescription = styled.div`
  margin-top: 0.5rem;
  font-size: 1.4rem;
`;

export const LoginHeaderWrap = styled(Div)`
  width: 100%;
  max-width: 420px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
`;

export const TitleSmall = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 8px;
`;

export const QuizList = styled(Div)``;

export const TitleBig = styled.h1`
  font-size: 4rem;
`;

export const Card = styled.div`
  height: 40rem;
  background-color: orange;
  margin-bottom: 2rem;
`;

export const LatestHistory = styled.div`
  font-size: 1.2rem;
  text-align: center;
  line-height: 3.8rem;
  border-radius: 0.4rem;
  background-color: #f0f2f1;
  margin-bottom: 2rem;
  b {
    font-weight: bold;
  }
`;

export const SocialWrap = styled.div`
  margin-bottom: 10rem;
  .social-btn-wrap {
    margin-bottom: 1rem;
    &:last-child {
      margin: 0;
    }
  }
`;

export const Agreement = styled.div`
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.6;
  span {
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const Image = styled.div``;

export const Title = styled.div``;

export const Description = styled.div``;

export const HospitalName = styled.div``;

export const Count = styled.div``;
